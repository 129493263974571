<script lang="ts" setup>
const error = useError();

useSeoMeta({
  title: 'アクセスできません',
});

const statusCode = computed(() => {
  return error.value?.statusCode ?? 500;
});

const message = computed(() => {
  if (statusCode.value === 404) {
    return 'お探しのページが見つかりませんでした';
  }

  return error.value?.message
    ? `エラーが発生しました\n${error.value.message}`
    : 'エラーが発生しました';
});
</script>

<template>
  <NuxtLayout :hide-side-bar="true">
    <div class="py-20 md:pb-5 px-2 md:px-0 text-center">
      <p class="mb-5 text-6xl font-bold" data-testid="status-code">
        {{ statusCode }}
      </p>
      <p
        class="text-red-400 text-xl mb-5 whitespace-pre-wrap"
        data-testid="message"
      >
        {{ message }}
      </p>
      <img
        src="~/assets/img/mochipyon_cry.png"
        class="md:w-[500px] mx-auto mb-5"
      />
      <NuxtLink to="/" class="underline">トップページに戻る</NuxtLink>
    </div>
  </NuxtLayout>
</template>
