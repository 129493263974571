export function useUserAgentState() {
  const userAgentState = useState('userAgent', () => '');

  const isFromNavel = computed(() => {
    return /navel/.test(userAgentState.value);
  });
  const isFromSp = computed(() => {
    return /iPhone|Android|Mobile/.test(userAgentState.value);
  });

  return {
    userAgentState,
    isFromNavel,
    isFromSp,
  };
}
