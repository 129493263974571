import type { RouteLocationRaw } from '#vue-router';

export type BreadCrumb = {
  text: string;
  to?: RouteLocationRaw;
};

export function useBreadcrumbs() {
  const breadcrumbsState = useState<BreadCrumb[]>('breadcrumbs', () => []);

  function setBreadcrumbs(newValue: BreadCrumb[]) {
    breadcrumbsState.value = newValue;
  }

  function clearBreadcrumbs() {
    breadcrumbsState.value = [];
  }

  return {
    breadcrumbsState,
    setBreadcrumbs,
    clearBreadcrumbs,
  };
}
