<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const route = useRoute();
const config = useRuntimeConfig();

useHead({
  htmlAttrs: {
    lang: 'ja',
  },
  templateParams: {
    siteName: 'ダイエットプラス',
    separator: '|',
  },
  titleTemplate: '%s %separator %siteName',
  meta: [
    {
      property: 'SKYPE_TOOLBAR',
      content: 'SKYPE_TOOLBAR_PARSER_COMPATIBLE',
    },
  ],
  link: [
    {
      rel: 'shortcut icon',
      sizes: '32x32',
      href: '/favicon.ico',
      type: 'image/vnd.microsoft.icon',
    },
    {
      rel: 'icon',
      sizes: '32x32',
      href: '/favicon.ico',
      type: 'image/vnd.microsoft.icon',
    },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '/apple-touch-icon-152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon-180.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '167x167',
      href: '/apple-touch-icon-167.png',
    },
  ],
});

const { ogImage } = useOgImage();

useSeoMeta({
  keywords:
    'ダイエット,だいえっと,瘦せない,瘦せたい,ダイエット方法,食事,カロリー,レシピ,ダイエットプラス,ダイエットプラス,管理栄養士',
  ogUrl: `${config.public.siteUrl}${route.path}`,
  ogType: 'article',
  ogImage,
  ogSiteName: 'ダイエットプラス',
  author: 'ダイエットプラス',
  formatDetection: 'telephone=no',
});
</script>
