import type { Configuration } from 'rollbar';

interface CreateRollbarConfigInput {
  accessToken: string;
  commitHash: string;
  environment: string;
  scrubFields: string[];
  hostSafeList?: string[];
}

export function createRollbarConfig({
  accessToken,
  commitHash,
  scrubFields,
  environment,
  hostSafeList,
}: CreateRollbarConfigInput): Configuration {
  return {
    accessToken: accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: 'anonymize',
    environment: environment,
    scrubFields: scrubFields,
    nodeSourceMaps: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: commitHash,
        },
      },
    },
    hostSafeList: hostSafeList,
    ignoredMessages: [
      // NOTE: アドブロック関連のアドオンを入れていると出るエラーのようなので除外する
      // ref: https://support.google.com/youtube/thread/36764656/%E5%8B%95%E7%94%BB%E3%81%AB%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%81%97%E3%82%88%E3%81%86%E3%81%A8%E3%80%81%E5%85%A5%E5%8A%9B%E5%BE%8C%E3%81%AB%E3%80%8C%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%80%8D%E3%82%92%E6%8A%BC%E3%81%99%E3%81%A8%E4%B8%8B%E8%A8%98%E3%81%AE%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8%E3%81%8C%E5%87%BA%E3%81%A6%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%81%8C%E5%8F%8D%E6%98%A0%E3%81%95%E3%82%8C%E3%81%BE%E3%81%9B%E3%82%93%E3%80%82-networkerror-when-attempting-to-fetch-resource?hl=ja
      /NetworkError when attempting to fetch resource\./,
    ],
  };
}
